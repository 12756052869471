import { Button, Layout } from "antd";
import React, { useEffect, useState } from "react";

// TODO
// import { LoginSwiper } from "@components/organisms";
import Icon from "@ant-design/icons";
import MedioSVG from "@assets/svg/medio_logo.svg";
import SkilrikiSVG from "@assets/svg/rafraen_skilriki.svg";
import styles from "@components/organisms/loginSwiper/login.module.scss";
import { withLoginRedirect } from "@utils";

const MedioLogo = (props: any) => <Icon component={MedioSVG} {...props} />;

const Login = ({ islandUrl }) => {
  // Clear prescription cart if user is logged out.
  useEffect(() => {
    localStorage.removeItem("prescriptionCart");
  }, []);

  return (
    <Layout>
      <div className={styles["login-page"]}>
        {/* Sidebar */}
        <div className={styles["login-side"]}>
          <MedioLogo className={styles["site-logo"]} />
          <div className={styles["login-info"]}>
            <p className={styles["login-title"]}>
              Lyfseðlarnir þínir á einum stað
            </p>
            <p className={styles["login-description"]}>
              Með því að skrá þig inn geturðu séð þá lyfseðla sem þú átt í
              lyfjagáttinni, fengið heimseint eða sótt í það apótek eða póstbox
              sem þér hentar
            </p>
            <Button className={`${styles["login-button"]} primary-gradient`}>
              <a href={`https://innskraning.island.is/?id=${islandUrl}&qaa=4`}>
                <SkilrikiSVG /> Rafræn innskráning
              </a>
            </Button>
          </div>
        </div>

        {/* Content */}
        {/* TODO: SVG imports remove path elements if imported */}
        <div className={styles["login-content"]}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="3000"
            height="1500"
            preserveAspectRatio="xMidYMid"
            viewBox="0 0 3000 1500"
          >
            <g transform="">
              <linearGradient
                id="lg-0.42669582762089364"
                x1="0"
                x2="0.5"
                y1="0"
                y2="0"
                gradientUnits="objectBoundingBox"
              >
                <stop stopColor="#d4dff6" offset="0"></stop>
                <stop stopColor="#98b6c0" offset="1"></stop>
              </linearGradient>
              <path d="" fill="url(#lg-0.42669582762089364)" opacity="1">
                <animate
                  attributeName="d"
                  dur="14"
                  repeatCount="indefinite"
                  keyTimes="0;0.33;0.67;1"
                  calcMode="spline"
                  keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                  begin="0s"
                  values="M -2608.2555680750793 1500.0000000000005Q -2072.243950446984 673.4591410658378 -1537.174063370664 -148.22495700083846T 1266.953720666875 -1166.8840015331912T 4733.0462793331235 -1166.8840015331912T 7537.174063370663 -148.2249570008396T 8608.25556807508 1499.9999999999993;M -2608.2555680750793 1500.0000000000005Q -2178.5762907913304 649.3186802775743 -1537.174063370664 -148.22495700083846T 1266.953720666875 -1166.8840015331912T 4733.0462793331235 -1166.8840015331912T 7537.174063370663 -148.2249570008396T 8608.25556807508 1499.9999999999993;M -2608.2555680750793 1500.0000000000005Q -2319.50403586007 656.6780069569209 -1537.174063370664 -148.22495700083846T 1266.953720666875 -1166.8840015331912T 4733.0462793331235 -1166.8840015331912T 7537.174063370663 -148.2249570008396T 8608.25556807508 1499.9999999999993;M -2608.2555680750793 1500.0000000000005Q -2072.243950446984 673.4591410658378 -1537.174063370664 -148.22495700083846T 1266.953720666875 -1166.8840015331912T 4733.0462793331235 -1166.8840015331912T 7537.174063370663 -148.2249570008396T 8608.25556807508 1499.9999999999993"
                ></animate>
              </path>
              <path d="" fill="url(#lg-0.42669582762089364)" opacity="1">
                <animate
                  attributeName="d"
                  dur="14"
                  repeatCount="indefinite"
                  keyTimes="0;0.33;0.67;1"
                  calcMode="spline"
                  keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                  begin="-0.7142857142857143s"
                  values="M -2327.8427896713256 1500.0000000000002Q -2030.0320896375952 687.0127269040288 -1310.3153602021312 -65.81370915079674T 1353.606034633531 -1033.5398014565317T 4646.3939653664675 -1033.5398014565321T 7310.31536020213 -65.81370915079765T 8327.842789671326 1499.9999999999993;M -2327.8427896713256 1500.0000000000002Q -1873.6383987656454 683.3423867260818 -1310.3153602021312 -65.81370915079674T 1353.606034633531 -1033.5398014565317T 4646.3939653664675 -1033.5398014565321T 7310.31536020213 -65.81370915079765T 8327.842789671326 1499.9999999999993;M -2327.8427896713256 1500.0000000000002Q -2030.8798878512944 680.5395076608045 -1310.3153602021312 -65.81370915079674T 1353.606034633531 -1033.5398014565317T 4646.3939653664675 -1033.5398014565321T 7310.31536020213 -65.81370915079765T 8327.842789671326 1499.9999999999993;M -2327.8427896713256 1500.0000000000002Q -2030.0320896375952 687.0127269040288 -1310.3153602021312 -65.81370915079674T 1353.606034633531 -1033.5398014565317T 4646.3939653664675 -1033.5398014565321T 7310.31536020213 -65.81370915079765T 8327.842789671326 1499.9999999999993"
                ></animate>
              </path>
              <path d="" fill="url(#lg-0.42669582762089364)" opacity="1">
                <animate
                  attributeName="d"
                  dur="14"
                  repeatCount="indefinite"
                  keyTimes="0;0.33;0.67;1"
                  calcMode="spline"
                  keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                  begin="-1."
                  values="M -2047.430011267572 1500.0000000000002Q -1550.0430374327407 724.0033513672893 -1083.4566570335983 16.597538699245206T 1440.2583486001872 -900.1956013798722T 4559.741651399811 -900.1956013798726T 7083.456657033597 16.597538699244296T 8047.430011267572 1499.9999999999993;M -2047.430011267572 1500.0000000000002Q -1785.9766766697303 724.8252731210556 -1083.4566570335983 16.597538699245206T 1440.2583486001872 -900.1956013798722T 4559.741651399811 -900.1956013798726T 7083.456657033597 16.597538699244296T 8047.430011267572 1499.9999999999993;M -2047.430011267572 1500.0000000000002Q -1604.6247043014391 744.7044742296089 -1083.4566570335983 16.597538699245206T 1440.2583486001872 -900.1956013798722T 4559.741651399811 -900.1956013798726T 7083.456657033597 16.597538699244296T 8047.430011267572 1499.9999999999993;M -2047.430011267572 1500.0000000000002Q -1550.0430374327407 724.0033513672893 -1083.4566570335983 16.597538699245206T 1440.2583486001872 -900.1956013798722T 4559.741651399811 -900.1956013798726T 7083.456657033597 16.597538699244296T 8047.430011267572 1499.9999999999993"
                ></animate>
              </path>
              <path d="" fill="url(#lg-0.42669582762089364)" opacity="1">
                <animate
                  attributeName="d"
                  dur="14"
                  repeatCount="indefinite"
                  keyTimes="0;0.33;0.67;1"
                  calcMode="spline"
                  keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                  begin="-2.1428571428571432s"
                  values="M -1767.0172328638173 1500.0000000000002Q -1315.347985649897 803.355328662056 -856.5979538650645 99.00878654928715T 1526.9106625668437 -766.8514013032122T 4473.0893374331545 -766.8514013032127T 6856.5979538650645 99.00878654928647T 7767.017232863817 1499.9999999999993;M -1767.0172328638173 1500.0000000000002Q -1285.5499892190128 800.9003080832446 -856.5979538650645 99.00878654928715T 1526.9106625668437 -766.8514013032122T 4473.0893374331545 -766.8514013032127T 6856.5979538650645 99.00878654928647T 7767.017232863817 1499.9999999999993;M -1767.0172328638173 1500.0000000000002Q -1433.3364859646545 803.1209572167206 -856.5979538650645 99.00878654928715T 1526.9106625668437 -766.8514013032122T 4473.0893374331545 -766.8514013032127T 6856.5979538650645 99.00878654928647T 7767.017232863817 1499.9999999999993;M -1767.0172328638173 1500.0000000000002Q -1315.347985649897 803.355328662056 -856.5979538650645 99.00878654928715T 1526.9106625668437 -766.8514013032122T 4473.0893374331545 -766.8514013032127T 6856.5979538650645 99.00878654928647T 7767.017232863817 1499.9999999999993"
                ></animate>
              </path>
              <path d="" fill="url(#lg-0.42669582762089364)" opacity="1">
                <animate
                  attributeName="d"
                  dur="14"
                  repeatCount="indefinite"
                  keyTimes="0;0.33;0.67;1"
                  calcMode="spline"
                  keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                  begin="-2.857142857142857s"
                  values="M -1486.6044544600636 1500.0000000000002Q -1111.8790037704111 648.5387337751731 -172.50843425048242 -86.25421712524076T 2999.999999999999 -743.3022272300318T 6172.5084342504815 -86.25421712524121T 7486.604454460064 1499.9999999999995;M -1486.6044544600636 1500.0000000000002Q -1003.2049528301914 690.7203166788572 -172.50843425048242 -86.25421712524076T 2999.999999999999 -743.3022272300318T 6172.5084342504815 -86.25421712524121T 7486.604454460064 1499.9999999999995;M -1486.6044544600636 1500.0000000000002Q -970.6067764617105 693.4851450791506 -172.50843425048242 -86.25421712524076T 2999.999999999999 -743.3022272300318T 6172.5084342504815 -86.25421712524121T 7486.604454460064 1499.9999999999995;M -1486.6044544600636 1500.0000000000002Q -1111.8790037704111 648.5387337751731 -172.50843425048242 -86.25421712524076T 2999.999999999999 -743.3022272300318T 6172.5084342504815 -86.25421712524121T 7486.604454460064 1499.9999999999995"
                ></animate>
              </path>
              <path d="" fill="url(#lg-0.42669582762089364)" opacity="1">
                <animate
                  attributeName="d"
                  dur="14"
                  repeatCount="indefinite"
                  keyTimes="0;0.33;0.67;1"
                  calcMode="spline"
                  keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                  begin="-3.5714285714285716s"
                  values="M -1206.19167605631 1500.0000000000002Q -812.8350044833401 713.562195187428 25.773342890172444 12.886671445086677T 2999.999999999999 -603.095838028155T 5974.226657109826 12.886671445086222T 7206.19167605631 1499.9999999999995;M -1206.19167605631 1500.0000000000002Q -731.8402506903867 747.8382262584832 25.773342890172444 12.886671445086677T 2999.999999999999 -603.095838028155T 5974.226657109826 12.886671445086222T 7206.19167605631 1499.9999999999995;M -1206.19167605631 1500.0000000000002Q -828.6164563198618 735.4223448187465 25.773342890172444 12.886671445086677T 2999.999999999999 -603.095838028155T 5974.226657109826 12.886671445086222T 7206.19167605631 1499.9999999999995;M -1206.19167605631 1500.0000000000002Q -812.8350044833401 713.562195187428 25.773342890172444 12.886671445086677T 2999.999999999999 -603.095838028155T 5974.226657109826 12.886671445086222T 7206.19167605631 1499.9999999999995"
                ></animate>
              </path>
              <path d="" fill="url(#lg-0.42669582762089364)" opacity="1">
                <animate
                  attributeName="d"
                  dur="14"
                  repeatCount="indefinite"
                  keyTimes="0;0.33;0.67;1"
                  calcMode="spline"
                  keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                  begin="-4.2857142857142865s"
                  values="M -925.7788976525558 1500.0000000000002Q -560.9052511681612 767.7564828525549 224.05512003082777 112.02756001541434T 2999.999999999999 -462.8894488262779T 5775.944879969171 112.02756001541388T 6925.778897652555 1499.9999999999995;M -925.7788976525558 1500.0000000000002Q -554.6800298432013 771.2010522592259 224.05512003082777 112.02756001541434T 2999.999999999999 -462.8894488262779T 5775.944879969171 112.02756001541388T 6925.778897652555 1499.9999999999995;M -925.7788976525558 1500.0000000000002Q -609.9328727610477 764.754106298005 224.05512003082777 112.02756001541434T 2999.999999999999 -462.8894488262779T 5775.944879969171 112.02756001541388T 6925.778897652555 1499.9999999999995;M -925.7788976525558 1500.0000000000002Q -560.9052511681612 767.7564828525549 224.05512003082777 112.02756001541434T 2999.999999999999 -462.8894488262779T 5775.944879969171 112.02756001541388T 6925.778897652555 1499.9999999999995"
                ></animate>
              </path>
              <path d="" fill="url(#lg-0.42669582762089364)" opacity="1">
                <animate
                  attributeName="d"
                  dur="14"
                  repeatCount="indefinite"
                  keyTimes="0;0.33;0.67;1"
                  calcMode="spline"
                  keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                  begin="-5s"
                  values="M -645.3661192488016 1500.0000000000002Q -117.46971968079652 837.7058931678685 422.3368971714831 211.168448585742T 2999.9999999999995 -322.6830596244008T 5577.663102828516 211.16844858574154T 6645.366119248802 1499.9999999999995;M -645.3661192488016 1500.0000000000002Q -338.078752888679 830.0363602919243 422.3368971714831 211.168448585742T 2999.9999999999995 -322.6830596244008T 5577.663102828516 211.16844858574154T 6645.366119248802 1499.9999999999995;M -645.3661192488016 1500.0000000000002Q -241.23407437117248 806.6675276434692 422.3368971714831 211.168448585742T 2999.9999999999995 -322.6830596244008T 5577.663102828516 211.16844858574154T 6645.366119248802 1499.9999999999995;M -645.3661192488016 1500.0000000000002Q -117.46971968079652 837.7058931678685 422.3368971714831 211.168448585742T 2999.9999999999995 -322.6830596244008T 5577.663102828516 211.16844858574154T 6645.366119248802 1499.9999999999995"
                ></animate>
              </path>
              <path d="" fill="url(#lg-0.42669582762089364)" opacity="1">
                <animate
                  attributeName="d"
                  dur="14"
                  repeatCount="indefinite"
                  keyTimes="0;0.33;0.67;1"
                  calcMode="spline"
                  keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                  begin="-5.714285714285714s"
                  values="M -364.95334084504793 1500.0000000000002Q 193.07249235793006 726.588943188691 1317.5233295774744 42.932462139435984T 4682.476670422522 42.932462139435074T 6364.953340845048 1499.9999999999995;M -364.95334084504793 1500.0000000000002Q 254.46285619179025 665.7756802448076 1317.5233295774744 42.932462139435984T 4682.476670422522 42.932462139435074T 6364.953340845048 1499.9999999999995;M -364.95334084504793 1500.0000000000002Q 290.49724660237916 674.685063100682 1317.5233295774744 42.932462139435984T 4682.476670422522 42.932462139435074T 6364.953340845048 1499.9999999999995;M -364.95334084504793 1500.0000000000002Q 193.07249235793006 726.588943188691 1317.5233295774744 42.932462139435984T 4682.476670422522 42.932462139435074T 6364.953340845048 1499.9999999999995"
                ></animate>
              </path>
              <path d="" fill="url(#lg-0.42669582762089364)" opacity="1">
                <animate
                  attributeName="d"
                  dur="14"
                  repeatCount="indefinite"
                  keyTimes="0;0.33;0.67;1"
                  calcMode="spline"
                  keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                  begin="-6.428571428571429s"
                  values="M -84.54056244129379 1500.0000000000002Q 430.23791131734924 781.1951001565491 1457.7297187793517 164.35475696114986T 4542.270281220644 164.35475696114895T 6084.540562441294 1499.9999999999995;M -84.54056244129379 1500.0000000000002Q 499.97131613326246 742.4474259758144 1457.7297187793517 164.35475696114986T 4542.270281220644 164.35475696114895T 6084.540562441294 1499.9999999999995;M -84.54056244129379 1500.0000000000002Q 362.2740735019861 745.3234654751468 1457.7297187793517 164.35475696114986T 4542.270281220644 164.35475696114895T 6084.540562441294 1499.9999999999995;M -84.54056244129379 1500.0000000000002Q 430.23791131734924 781.1951001565491 1457.7297187793517 164.35475696114986T 4542.270281220644 164.35475696114895T 6084.540562441294 1499.9999999999995"
                ></animate>
              </path>
              <path d="" fill="url(#lg-0.42669582762089364)" opacity="1">
                <animate
                  attributeName="d"
                  dur="14"
                  repeatCount="indefinite"
                  keyTimes="0;0.33;0.67;1"
                  calcMode="spline"
                  keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                  begin="-7.142857142857143s"
                  values="M 195.87221596246036 1500.0000000000002Q 799.0319571053092 864.8605717513364 1597.936107981229 285.7770517828635T 4402.0638920187685 285.7770517828628T 5804.12778403754 1499.9999999999995;M 195.87221596246036 1500.0000000000002Q 683.7717171780232 845.9504527709934 1597.936107981229 285.7770517828635T 4402.0638920187685 285.7770517828628T 5804.12778403754 1499.9999999999995;M 195.87221596246036 1500.0000000000002Q 659.0966592581049 830.3160558684777 1597.936107981229 285.7770517828635T 4402.0638920187685 285.7770517828628T 5804.12778403754 1499.9999999999995;M 195.87221596246036 1500.0000000000002Q 799.0319571053092 864.8605717513364 1597.936107981229 285.7770517828635T 4402.0638920187685 285.7770517828628T 5804.12778403754 1499.9999999999995"
                ></animate>
              </path>
              <path d="" fill="url(#lg-0.42669582762089364)" opacity="1">
                <animate
                  attributeName="d"
                  dur="14"
                  repeatCount="indefinite"
                  keyTimes="0;0.33;0.67;1"
                  calcMode="spline"
                  keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                  begin="-7.857142857142857s"
                  values="M 476.28499436621405 1500.0000000000002Q 832.9837128016575 897.9750007710403 1738.142497183106 407.19934660457693T 4261.857502816892 407.19934660457625T 5523.715005633786 1499.9999999999998;M 476.28499436621405 1500.0000000000002Q 857.8056750722103 933.4274027604341 1738.142497183106 407.19934660457693T 4261.857502816892 407.19934660457625T 5523.715005633786 1499.9999999999998;M 476.28499436621405 1500.0000000000002Q 856.5681381510999 899.0114793421599 1738.142497183106 407.19934660457693T 4261.857502816892 407.19934660457625T 5523.715005633786 1499.9999999999998;M 476.28499436621405 1500.0000000000002Q 832.9837128016575 897.9750007710403 1738.142497183106 407.19934660457693T 4261.857502816892 407.19934660457625T 5523.715005633786 1499.9999999999998"
                ></animate>
              </path>
              <path d="" fill="url(#lg-0.42669582762089364)" opacity="1">
                <animate
                  attributeName="d"
                  dur="14"
                  repeatCount="indefinite"
                  keyTimes="0;0.33;0.67;1"
                  calcMode="spline"
                  keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                  begin="-8.571428571428573s"
                  values="M 756.6977727699682 1500.0000000000002Q 1552.9550732620203 749.9713965333964 2999.9999999999995 378.3488863849841T 5243.302227230031 1499.9999999999998;M 756.6977727699682 1500.0000000000002Q 1597.4409742611665 752.6361168227019 2999.9999999999995 378.3488863849841T 5243.302227230031 1499.9999999999998;M 756.6977727699682 1500.0000000000002Q 1573.7205473404756 773.1085908281486 2999.9999999999995 378.3488863849841T 5243.302227230031 1499.9999999999998;M 756.6977727699682 1500.0000000000002Q 1552.9550732620203 749.9713965333964 2999.9999999999995 378.3488863849841T 5243.302227230031 1499.9999999999998"
                ></animate>
              </path>
              <path d="" fill="url(#lg-0.42669582762089364)" opacity="1">
                <animate
                  attributeName="d"
                  dur="14"
                  repeatCount="indefinite"
                  keyTimes="0;0.33;0.67;1"
                  calcMode="spline"
                  keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                  begin="-9"
                  values="M 1037.1105511737221 1500.0000000000002Q 1711.4333948622543 888.0476109551478 2999.9999999999995 518.5552755868611T 4962.889448826278 1499.9999999999998;M 1037.1105511737221 1500.0000000000002Q 1612.2263490627383 837.892399505489 2999.9999999999995 518.5552755868611T 4962.889448826278 1499.9999999999998;M 1037.1105511737221 1500.0000000000002Q 1690.5010018304179 875.0236491927157 2999.9999999999995 518.5552755868611T 4962.889448826278 1499.9999999999998;M 1037.1105511737221 1500.0000000000002Q 1711.4333948622543 888.0476109551478 2999.9999999999995 518.5552755868611T 4962.889448826278 1499.9999999999998"
                ></animate>
              </path>
              <path d="" fill="url(#lg-0.42669582762089364)" opacity="1">
                <animate
                  attributeName="d"
                  dur="14"
                  repeatCount="indefinite"
                  keyTimes="0;0.33;0.67;1"
                  calcMode="spline"
                  keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                  begin="-10s"
                  values="M 1317.523329577476 1500Q 1846.740391592972 968.5908333051907 2999.9999999999995 658.761664788738T 4682.476670422524 1499.9999999999998;M 1317.523329577476 1500Q 1853.3253937133757 976.5542600075838 2999.9999999999995 658.761664788738T 4682.476670422524 1499.9999999999998;M 1317.523329577476 1500Q 1936.911266762746 917.5703537589422 2999.9999999999995 658.761664788738T 4682.476670422524 1499.9999999999998;M 1317.523329577476 1500Q 1846.740391592972 968.5908333051907 2999.9999999999995 658.761664788738T 4682.476670422524 1499.9999999999998"
                ></animate>
              </path>
              <path d="" fill="url(#lg-0.42669582762089364)" opacity="1">
                <animate
                  attributeName="d"
                  dur="14"
                  repeatCount="indefinite"
                  keyTimes="0;0.33;0.67;1"
                  calcMode="spline"
                  keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                  begin="-10.714285714285715s"
                  values="M 1597.9361079812302 1500Q 2071.0627164046628 1093.8120337217533 2999.9999999999995 798.9680539906151T 4402.06389201877 1499.9999999999998;M 1597.9361079812302 1500Q 2040.2293558067727 1038.0187545816325 2999.9999999999995 798.9680539906151T 4402.06389201877 1499.9999999999998;M 1597.9361079812302 1500Q 2110.847406050386 1101.2266491634332 2999.9999999999995 798.9680539906151T 4402.06389201877 1499.9999999999998;M 1597.9361079812302 1500Q 2071.0627164046628 1093.8120337217533 2999.9999999999995 798.9680539906151T 4402.06389201877 1499.9999999999998"
                ></animate>
              </path>
              <path d="" fill="url(#lg-0.42669582762089364)" opacity="1">
                <animate
                  attributeName="d"
                  dur="14"
                  repeatCount="indefinite"
                  keyTimes="0;0.33;0.67;1"
                  calcMode="spline"
                  keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                  begin="-11.428571428571429s"
                  values="M 1878.348886384984 1500Q 3000 1064.235025753164 4121.651113615016 1499.9999999999998;M 1878.348886384984 1500Q 3000 1004.253553826308 4121.651113615016 1499.9999999999998;M 1878.348886384984 1500Q 3000 1026.1668914303984 4121.651113615016 1499.9999999999998;M 1878.348886384984 1500Q 3000 1064.235025753164 4121.651113615016 1499.9999999999998"
                ></animate>
              </path>
              <path d="" fill="url(#lg-0.42669582762089364)" opacity="1">
                <animate
                  attributeName="d"
                  dur="14"
                  repeatCount="indefinite"
                  keyTimes="0;0.33;0.67;1"
                  calcMode="spline"
                  keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                  begin="-12.142857142857142s"
                  values="M 2158.761664788738 1500Q 3000 1127.8403519066947 3841.238335211262 1500;M 2158.761664788738 1500Q 3000 1157.1603918575292 3841.238335211262 1500;M 2158.761664788738 1500Q 3000 1103.14944775825 3841.238335211262 1500;M 2158.761664788738 1500Q 3000 1127.8403519066947 3841.238335211262 1500"
                ></animate>
              </path>
              <path d="" fill="url(#lg-0.42669582762089364)" opacity="1">
                <animate
                  attributeName="d"
                  dur="14"
                  repeatCount="indefinite"
                  keyTimes="0;0.33;0.67;1"
                  calcMode="spline"
                  keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                  begin="-12.857142857142858s"
                  values="M 2439.174443192492 1500Q 3000 1283.9413080371228 3560.825556807508 1500;M 2439.174443192492 1500Q 3000 1261.6062277985773 3560.825556807508 1500;M 2439.174443192492 1500Q 3000 1308.2727826664693 3560.825556807508 1500;M 2439.174443192492 1500Q 3000 1283.9413080371228 3560.825556807508 1500"
                ></animate>
              </path>
              <path d="" fill="url(#lg-0.42669582762089364)" opacity="1">
                <animate
                  attributeName="d"
                  dur="14"
                  repeatCount="indefinite"
                  keyTimes="0;0.33;0.67;1"
                  calcMode="spline"
                  keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                  begin="-13.571428571428573s"
                  values="M 2719.587221596246 1500Q 3000 1367.6113612912004 3280.412778403754 1500;M 2719.587221596246 1500Q 3000 1395.1765320157485 3280.412778403754 1500;M 2719.587221596246 1500Q 3000 1466.526198126969 3280.412778403754 1500;M 2719.587221596246 1500Q 3000 1367.6113612912004 3280.412778403754 1500"
                ></animate>
              </path>
            </g>
          </svg>

          <div className={styles["top-row"]}>
            <a>Spurningar</a>
            <a>Um Medio</a>
          </div>
          <div className={styles["login-carousel"]}>
            <div className={styles["carousel-container"]}>
              {/** //TODO */}
              {/* <LoginSwiper /> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const getServerSideProps = withLoginRedirect(async () => {
  return {
    props: {
      islandUrl: process.env.ISLAND_URL,
    },
  };
});

export default Login;
